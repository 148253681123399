


















































































































































import Vue from "vue";
import { ResponseBatchingView } from "@interface/batching.interface";
import { batchingService } from "@service/batching.service";
import MNotificationVue from "@/mixins/MNotification.vue";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import { createNamespacedHelpers } from "vuex";
import localStorageService from "@/services/localStorage.service";
import { IAuthorities } from "@interface/auth.interface";

interface ITableRow extends ResponseBatchingView {
  key: any;
  no: number;
}

const { mapState, mapMutations } = createNamespacedHelpers("batchingStore");

export default Vue.extend({
  name: "Batching",
  mixins: [
    MNotificationVue
  ],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      dtListBatching: [] as ITableRow[],
      loading: {
        batching: false
      },
      selectedRowKeys: [],
      visibleDrawer: false,
      dtListSelectedBatch: [] as ITableRow[],
      isLocationMixed: false,
      userPrivileges: [] as IAuthorities[]
    };
  },
  computed: {
    ...mapState({
      storeBatching: (state: any) => state.theBatch
    }),
    hasPrivWarehouseTransfer(): boolean {
      const priv = this.userPrivileges.find(x => x.key === "warehouse-transfer" && (x.privilege.create || x.privilege.update));
      return !!priv;
    }
  },
  mounted() {
    this.getUserPrivileges();
    this.fetchList();
  },
  methods: {
    moment,
    ...mapMutations([
      "SET_BATCHING_TRANSFER"
    ]),
    getListBatching(): Promise<ResponseBatchingView[]> {
      return batchingService.getListBatching();
    },
    showDrawer() {
      this.visibleDrawer = true;
    },
    onCloseDrawer() {
      this.visibleDrawer = false;
    },
    getUserPrivileges(): void {
      const privileges = localStorageService.loadUserPrivilege();
      this.userPrivileges = privileges;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      const newArr = this.dtListBatching.filter(x => selectedRowKeys.includes(x.batchNumberId));
      const tempArr = [...newArr];
      let flag = false;
      for (const x of tempArr) {
        if (flag) break;
        for (const y of newArr) {
          if (flag) break;
          if (x.batchNumberId !== y.batchNumberId && x.locationId !== y.locationId) {
            this.showNotifWarning("notif_found_mixed_location");
            flag = true;
          }
        }
      }
      if (!flag) {
        this.dtListSelectedBatch = [...newArr];
      }
      this.isLocationMixed = flag;
    },
    proceed(): void {
      const payload = {
        sourceLocationId: this.dtListSelectedBatch[0].locationId || "",
        sourceLocationName: this.dtListSelectedBatch[0].locationName || "",
        transferLines: this.dtListSelectedBatch.map(x => {
          return {
            batchId: x.batchNumberId || "",
            batchNumber: x.batchNumber || "",
            productId: x.productId || "",
            productCode: x.productCode || "",
            productName: x.productName || "",
            uomId: x.uomId || "",
            uom: x.uom || "",
            qty: x.qty || 0
          };
        })
      };
      this.SET_BATCHING_TRANSFER(payload);
      this.$router.push({ name: "logistic.warehousetransfer.form", query: { batching: "true" } });
    },
    async fetchList(): Promise<void> {
      try {
        this.loading.batching = true;
        const res = await this.getListBatching();
        this.dtListBatching = res.map((x, i) => {
          return {
            key: x.batchNumberId,
            no: i + 1,
            ...x
          };
        });
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading.batching = false;
      }
    }
  },
});

