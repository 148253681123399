import { Api } from "@/models/class/api.class";
import { ResponseBatchingView } from "@interface/batching.interface";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class BatchingServices extends HttpClient {
  constructor() {
    super();
  }

  getListBatching(): Promise<ResponseBatchingView[]> {
    return this.get<ResponseBatchingView[]>(Api.Batching)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }
}

export const batchingService = new BatchingServices();
