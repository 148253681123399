var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("a-divider", [_vm._v(_vm._s(_vm.$t("lbl_batching_h75")))]),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c("a-col", { attrs: { sm: 24, md: 12 } }, [
            _c("span", { staticClass: "ant-form-text" }, [
              _vm._v(
                _vm._s(_vm.$t("lbl_warehouse_transfer_chiller_to_freezer"))
              )
            ])
          ]),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 12, align: "end" } },
            [
              _c("a-badge", {
                attrs: {
                  count: _vm.selectedRowKeys.length,
                  "show-zero": "",
                  "number-style": { backgroundColor: "#1A508B" }
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            [
              _c(
                "a-table",
                {
                  attrs: {
                    "data-source": _vm.dtListBatching,
                    "row-selection": {
                      selectedRowKeys: _vm.selectedRowKeys,
                      onChange: _vm.onSelectChange
                    },
                    size: "small",
                    loading: _vm.loading.batching,
                    scroll: { y: 320 }
                  }
                },
                [
                  _c(
                    "a-table-column",
                    { key: "no", attrs: { "data-index": "no", width: 40 } },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_no")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "productCode",
                      attrs: { "data-index": "productCode", width: 150 }
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_product_code")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "productName",
                      attrs: { "data-index": "productName" }
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_product_name")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "batchNumber",
                      attrs: { "data-index": "batchNumber" }
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_batch_number")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "packDate",
                      attrs: { "data-index": "packDate", width: 100 },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("date")(record.packDate)) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_pack_date")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "locationCode",
                      attrs: { "data-index": "locationCode" }
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_location")))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { align: "end" } },
            [
              _vm.hasPrivWarehouseTransfer
                ? _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "arrow-right",
                        disabled:
                          !_vm.selectedRowKeys.length || _vm.isLocationMixed
                      },
                      on: { click: _vm.showDrawer }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_transfer")) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-drawer",
        {
          attrs: {
            title: _vm.$t("lbl_batching"),
            placement: "right",
            visible: _vm.visibleDrawer,
            width: "640"
          },
          on: { close: _vm.onCloseDrawer }
        },
        [
          _vm._l(_vm.dtListSelectedBatch, function(item, i) {
            return [
              _c(
                "a-row",
                { key: item.batchNumberId, attrs: { gutter: [16, 16] } },
                [
                  _c("a-col", [
                    _c("span", { staticClass: "text-subtitle-2" }, [
                      _vm._v(_vm._s(i + 1) + ".")
                    ])
                  ]),
                  _c("a-col", [
                    _c("span", { staticClass: "text-subtitle-2" }, [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_product_code")) + ": ")
                    ]),
                    _vm._v(" " + _vm._s(item.productCode) + " ")
                  ]),
                  _c("a-col", [
                    _c("span", { staticClass: "text-subtitle-2" }, [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_product_name")) + ": ")
                    ]),
                    _vm._v(" " + _vm._s(item.productName) + " ")
                  ]),
                  _c("a-col", [
                    _c("span", { staticClass: "text-subtitle-2" }, [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_batch_number")) + ": ")
                    ]),
                    _vm._v(" " + _vm._s(item.batchNumber) + " ")
                  ]),
                  _c("a-col", [
                    _c("span", { staticClass: "text-subtitle-2" }, [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_pack_date")) + ": ")
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm
                            .moment(item.packDate)
                            .format(_vm.DEFAULT_DATE_FORMAT)
                        ) +
                        " "
                    )
                  ]),
                  _c("a-col", [
                    _c("span", { staticClass: "text-subtitle-2" }, [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_location")) + ": ")
                    ]),
                    _vm._v(" " + _vm._s(item.locationName) + " ")
                  ])
                ],
                1
              ),
              _c("a-divider", { key: i })
            ]
          }),
          _c(
            "div",
            { staticClass: "text-right" },
            [
              _c(
                "a-button",
                {
                  attrs: { icon: "arrow-right", type: "primary" },
                  on: { click: _vm.proceed }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_proceed")) + " ")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }